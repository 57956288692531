var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "h-100" },
    [
      _c(
        "b-colxx",
        { staticClass: "mx-auto my-auto", attrs: { xxs: "12", md: "10" } },
        [
          _c("b-card", { staticClass: "auth-card", attrs: { "no-body": "" } }, [
            _c("div", { staticClass: "position-relative image-side" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-side" },
              [
                _c("span", { staticClass: "logo-text" }, [
                  _vm._v(_vm._s(_vm.appTitle)),
                ]),
                _vm._v(" "),
                _c("h6", { staticClass: "mb-4" }, [_vm._v("Forgot Password")]),
                _vm._v(" "),
                _c(
                  "b-form",
                  {
                    staticClass: "av-tooltip tooltip-label-bottom",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.formSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "mb-4" },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [
                            _c("i", {
                              staticClass: "demo-icon iconsminds-email",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state: !_vm.$v.form.email.$error,
                            placeholder: "test@test.com",
                          },
                          model: {
                            value: _vm.$v.form.email.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.form.email, "$model", $$v)
                            },
                            expression: "$v.form.email.$model",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.$v.form.email.required
                          ? _c("div", { staticClass: "invalid-tooltip" }, [
                              _vm._v("Please enter your email address"),
                            ])
                          : !_vm.$v.form.email.email
                          ? _c("div", { staticClass: "invalid-tooltip" }, [
                              _vm._v("Please enter a valid email address"),
                            ])
                          : !_vm.$v.form.email.minLength
                          ? _c("div", { staticClass: "invalid-tooltip" }, [
                              _vm._v("Your email must be minimum 4 characters"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center",
                      },
                      [
                        _c("router-link", { attrs: { tag: "a", to: "/" } }, [
                          _vm._v("Cancel"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            class: {
                              "btn-multiple-state btn-shadow": true,
                              "show-spinner": _vm.processing,
                              "show-success":
                                !_vm.processing && _vm.loginError === false,
                              "show-fail": !_vm.processing && _vm.loginError,
                            },
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              size: "lg",
                              disabled: _vm.processing,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "spinner d-inline-block" },
                              [
                                _c("span", { staticClass: "bounce1" }),
                                _vm._v(" "),
                                _c("span", { staticClass: "bounce2" }),
                                _vm._v(" "),
                                _c("span", { staticClass: "bounce3" }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "icon success" }, [
                              _c("i", { staticClass: "simple-icon-check" }),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "icon fail" }, [
                              _c("i", {
                                staticClass: "simple-icon-exclamation",
                              }),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "label" }, [
                              _vm._v("SEND"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }