var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "h-100 login" },
    [
      _c(
        "b-colxx",
        { staticClass: "mx-auto my-auto", attrs: { xxs: "12", md: "10" } },
        [
          _c("b-card", { staticClass: "auth-card", attrs: { "no-body": "" } }, [
            _c(
              "div",
              { staticClass: "image-side" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12 align-self-center" } },
                  [_c("app-highlights")],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-side" },
              [
                _c("h5", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("user.welcome"))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "logo-text" }, [
                  _vm._v(_vm._s(_vm.$t("general.sitename"))),
                ]),
                _vm._v(" "),
                _c("language-selection", {
                  staticClass: "language-selection p-3",
                  on: { changed: _vm.onLanguageChange },
                  model: {
                    value: _vm.selectedLanguage,
                    callback: function ($$v) {
                      _vm.selectedLanguage = $$v
                    },
                    expression: "selectedLanguage",
                  },
                }),
                _vm._v(" "),
                _c("h6", { staticClass: "mb-4" }, [
                  _vm._v(_vm._s(_vm.$t("user.login-title"))),
                ]),
                _vm._v(" "),
                _c(
                  "b-form",
                  {
                    staticClass: "av-tooltip tooltip-label-bottom",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.formSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "mb-4" },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [
                            _c("i", {
                              staticClass: "demo-icon simple-icon-user",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state: _vm.$v.form.email.$dirty
                              ? _vm.$v.form.email.$error
                              : null,
                            placeholder: _vm.$t("user.email"),
                            autocomplete: "off",
                          },
                          model: {
                            value: _vm.$v.form.email.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.form.email, "$model", $$v)
                            },
                            expression: "$v.form.email.$model",
                          },
                        }),
                        _vm._v(" "),
                        _vm.$v.form.email.$dirty
                          ? _c("div", [
                              !_vm.$v.form.email.required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-tooltip" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("user.email-required")
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : !_vm.$v.form.email.email
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-tooltip" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("user.email-invalid")) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : !_vm.$v.form.email.minLength
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-tooltip" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("user.email-min-length")
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-5" },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [
                            _c("i", {
                              staticClass: "demo-icon simple-icon-lock",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.showPassword
                          ? _c("b-form-input", {
                              staticClass: "passwordInput",
                              attrs: {
                                type: "password",
                                state: !_vm.$v.form.password.$error,
                                placeholder: _vm.$t("user.password"),
                                autocomplete: "off",
                              },
                              model: {
                                value: _vm.$v.form.password.$model,
                                callback: function ($$v) {
                                  _vm.$set(_vm.$v.form.password, "$model", $$v)
                                },
                                expression: "$v.form.password.$model",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showPassword
                          ? _c("b-form-input", {
                              staticClass: "passwordInput",
                              attrs: {
                                type: "text",
                                state: !_vm.$v.form.password.$error,
                                placeholder: "Password",
                                autocomplete: "off",
                              },
                              model: {
                                value: _vm.$v.form.password.$model,
                                callback: function ($$v) {
                                  _vm.$set(_vm.$v.form.password, "$model", $$v)
                                },
                                expression: "$v.form.password.$model",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.showPassword
                          ? _c(
                              "b-input-group-prepend",
                              {
                                attrs: { "is-text": "" },
                                on: {
                                  click: function ($event) {
                                    _vm.showPassword = !_vm.showPassword
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa primary fa-eye-slash",
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showPassword
                          ? _c(
                              "b-input-group-prepend",
                              {
                                attrs: { "is-text": "" },
                                on: {
                                  click: function ($event) {
                                    _vm.showPassword = !_vm.showPassword
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa primary fa-eye" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$v.form.password.$dirty
                          ? _c("div", [
                              !_vm.$v.form.password.required
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-tooltip" },
                                    [
                                      _vm._v(
                                        "\n                Please enter your password\n              "
                                      ),
                                    ]
                                  )
                                : !_vm.$v.form.password.minLength ||
                                  !_vm.$v.form.password.maxLength
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-tooltip" },
                                    [
                                      _vm._v(
                                        "\n                Your password must be between 8 and 16 characters\n              "
                                      ),
                                    ]
                                  )
                                : !_vm.$v.form.password.valid
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-tooltip" },
                                    [
                                      _vm._v(
                                        "\n                Your password must contain at least one uppercase letter, one lowercase letter,\n                one number and one special character\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "align-self-center mr-auto" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "checkbox-1",
                                name: "checkbox-1",
                                value: true,
                                "unchecked-value": false,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeItem($event)
                                },
                              },
                              model: {
                                value: _vm.termsAccepted,
                                callback: function ($$v) {
                                  _vm.termsAccepted = $$v
                                },
                                expression: "termsAccepted",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("user.terms-accept")) +
                                  "\n                "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "active",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openModal($event)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("user.terms")))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "align-self-center" },
                        [
                          _c("processing-button", {
                            staticClass: "ml-2 btn-shadow",
                            attrs: {
                              variant: "primary",
                              size: "md",
                              label: _vm.$t("user.login-button"),
                              processing: _vm.processing,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalVmb",
          attrs: {
            id: "modalVmb",
            "modal-class": "modalVmb",
            size: "lg",
            "hide-header": "",
            "hide-footer": "",
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "vue-perfect-scrollbar",
            {
              staticClass: "scroll terms",
              attrs: {
                settings: { suppressScrollX: true, wheelPropagation: false },
              },
            },
            [
              _c("h1", [_vm._v("Terms of Use")]),
              _vm._v(" "),
              _vm.terms.type === 1
                ? _c(
                    "ol",
                    { staticClass: "all" },
                    _vm._l(_vm.terms.data, function (lev1, lev1Index) {
                      return _c("li", { key: lev1Index }, [
                        _c("b", [_vm._v(_vm._s(lev1.title))]),
                        _vm._v(" "),
                        _c(
                          "ol",
                          { staticClass: "number mt-2 mb-2" },
                          _vm._l(lev1.section, function (lev2, lev2Index) {
                            return _c(
                              "li",
                              { key: lev2Index, staticClass: "mt-2" },
                              [
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(lev2.section) },
                                }),
                                _vm._v(" "),
                                lev2.subSection
                                  ? _c(
                                      "ol",
                                      { staticClass: "alpha mt-2" },
                                      _vm._l(
                                        lev2.subSection,
                                        function (lev3, lev3Index) {
                                          return _c("li", { key: lev3Index }, [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(lev3.section),
                                              },
                                            }),
                                            _vm._v(" "),
                                            lev3.subSection
                                              ? _c(
                                                  "ol",
                                                  { staticClass: "roman" },
                                                  _vm._l(
                                                    lev3.subSection,
                                                    function (lev4, lev4Index) {
                                                      return _c(
                                                        "li",
                                                        { key: lev4Index },
                                                        [
                                                          _c("span", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                lev4.section
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    }),
                    0
                  )
                : _vm.terms.type === 2
                ? _c(
                    "div",
                    _vm._l(_vm.terms.data, function (item, index) {
                      return _c("p", { key: index, staticClass: "terms" }, [
                        _vm._v(
                          "\n          " + _vm._s(item.section) + "\n        "
                        ),
                      ])
                    }),
                    0
                  )
                : _c("div"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex float-right mt-3" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "light", size: "sm" },
                      on: { click: _vm.hideModal },
                    },
                    [_vm._v("CANCEL")]
                  ),
                  _vm._v(" "),
                  !_vm.termsAccepted
                    ? _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: { variant: "primary", size: "sm" },
                          on: { click: _vm.setTerms },
                        },
                        [_vm._v("I AGREE")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }