var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "h-100" },
    [
      _c(
        "b-colxx",
        { staticClass: "mx-auto my-auto", attrs: { xxs: "12", md: "10" } },
        [
          _c("b-card", { staticClass: "auth-card", attrs: { "no-body": "" } }, [
            _c("div", { staticClass: "position-relative image-side" }, [
              _c("p", { staticClass: "text-white h2" }, [
                _vm._v(_vm._s(_vm.$t("dashboards.magic-is-in-the-details"))),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "white mb-0" },
                [
                  _vm._v(
                    "\r\n                    Please use your credentials to login.\r\n                    "
                  ),
                  _c("br"),
                  _vm._v(
                    "If you are not a member, please\r\n                    "
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "white",
                      attrs: { tag: "a", to: "/user/register" },
                    },
                    [_vm._v("register")]
                  ),
                  _vm._v(".\r\n                "),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-side" },
              [
                _c("router-link", { attrs: { tag: "a", to: "/" } }, [
                  _c("span", { staticClass: "logo-single" }),
                ]),
                _vm._v(" "),
                _c("h6", { staticClass: "mb-4" }, [
                  _vm._v(_vm._s(_vm.$t("user.login-title"))),
                ]),
                _vm._v(" "),
                _c(
                  "b-form",
                  {
                    staticClass: "av-tooltip tooltip-label-bottom",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.formSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "has-float-label mb-4",
                        attrs: { label: _vm.$t("user.password") },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            type: "password",
                            state: !_vm.$v.form.password.$error,
                          },
                          model: {
                            value: _vm.$v.form.password.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.form.password, "$model", $$v)
                            },
                            expression: "$v.form.password.$model",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.$v.form.password.required
                          ? _c("b-form-invalid-feedback", [
                              _vm._v("Please enter your password"),
                            ])
                          : !_vm.$v.form.password.minLength ||
                            !_vm.$v.form.password.maxLength
                          ? _c("b-form-invalid-feedback", [
                              _vm._v(
                                "Your password must be between 4 and 16 characters"
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "has-float-label mb-4",
                        attrs: { label: _vm.$t("user.password-again") },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            type: "password",
                            state: !_vm.$v.form.passwordAgain.$error,
                          },
                          model: {
                            value: _vm.$v.form.passwordAgain.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.form.passwordAgain, "$model", $$v)
                            },
                            expression: "$v.form.passwordAgain.$model",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.$v.form.passwordAgain.required
                          ? _c("b-form-invalid-feedback", [
                              _vm._v("Please enter your password again"),
                            ])
                          : !_vm.$v.form.passwordAgain.sameAsPassword
                          ? _c("b-form-invalid-feedback", [
                              _vm._v("Your inputs does not match"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center",
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { tag: "a", to: "/user/forgot-password" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("user.forgot-password-question"))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            class: {
                              "btn-multiple-state btn-shadow": true,
                              "show-spinner": _vm.processing,
                              "show-success":
                                !_vm.processing && _vm.loginError === false,
                              "show-fail": !_vm.processing && _vm.loginError,
                            },
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              size: "lg",
                              disabled: _vm.processing,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "spinner d-inline-block" },
                              [
                                _c("span", { staticClass: "bounce1" }),
                                _vm._v(" "),
                                _c("span", { staticClass: "bounce2" }),
                                _vm._v(" "),
                                _c("span", { staticClass: "bounce3" }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "icon success" }, [
                              _c("i", { staticClass: "simple-icon-check" }),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "icon fail" }, [
                              _c("i", {
                                staticClass: "simple-icon-exclamation",
                              }),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("user.reset-password-button"))
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }