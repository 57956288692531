<template>
  <b-row class="h-100 login">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
      <b-card class="auth-card" no-body>
        <div class="image-side">
          <b-col cols="12 align-self-center">
            <app-highlights />
          </b-col>
        </div>
        <div class="form-side">
          <h5 class="text-muted">{{ $t('user.welcome') }}</h5>
          <span class="logo-text">{{ $t('general.sitename') }}</span>
          <language-selection
            class="language-selection p-3"
            v-model="selectedLanguage"
            @changed="onLanguageChange"
          ></language-selection>
          <h6 class="mb-4">{{ $t('user.login-title') }}</h6>
          <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
            <b-input-group class="mb-4">
              <b-input-group-prepend is-text>
                <i class="demo-icon simple-icon-user"></i>
              </b-input-group-prepend>
              <b-form-input
                type="text"
                v-model="$v.form.email.$model"
                :state="$v.form.email.$dirty ? $v.form.email.$error : null"
                :placeholder="$t('user.email')"
                autocomplete="off"
              />
              <div v-if="$v.form.email.$dirty">
                <div v-if="!$v.form.email.required" class="invalid-tooltip">
                  {{ $t('user.email-required') }}
                </div>
                <div v-else-if="!$v.form.email.email" class="invalid-tooltip">
                  {{ $t('user.email-invalid') }}
                </div>
                <div v-else-if="!$v.form.email.minLength" class="invalid-tooltip">
                  {{ $t('user.email-min-length') }}
                </div>
              </div>
            </b-input-group>

            <b-input-group class="mb-5">
              <b-input-group-prepend is-text>
                <i class="demo-icon simple-icon-lock"></i>
              </b-input-group-prepend>
              <b-form-input
                class="passwordInput"
                v-if="!showPassword"
                type="password"
                v-model="$v.form.password.$model"
                :state="!$v.form.password.$error"
                :placeholder="$t('user.password')"
                autocomplete="off"
              />
              <b-form-input
                class="passwordInput"
                v-if="showPassword"
                type="text"
                v-model="$v.form.password.$model"
                :state="!$v.form.password.$error"
                placeholder="Password"
                autocomplete="off"
              />
              <b-input-group-prepend
                is-text
                v-if="!showPassword"
                @click="showPassword = !showPassword"
              >
                <i class="fa primary fa-eye-slash"></i>
              </b-input-group-prepend>
              <b-input-group-prepend
                is-text
                v-if="showPassword"
                @click="showPassword = !showPassword"
              >
                <i class="fa primary fa-eye"></i>
              </b-input-group-prepend>
              <div v-if="$v.form.password.$dirty">
                <div v-if="!$v.form.password.required" class="invalid-tooltip">
                  Please enter your password
                </div>
                <div
                  v-else-if="!$v.form.password.minLength || !$v.form.password.maxLength"
                  class="invalid-tooltip"
                >
                  Your password must be between 8 and 16 characters
                </div>
                <div v-else-if="!$v.form.password.valid" class="invalid-tooltip">
                  Your password must contain at least one uppercase letter, one lowercase letter,
                  one number and one special character
                </div>
              </div>
            </b-input-group>

            <div class="d-flex mb-3">
              <div class="align-self-center mr-auto">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="termsAccepted"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  @change="onChangeItem($event)"
                >
                  {{ $t('user.terms-accept') }}
                  <a class="active" href="#" @click="openModal($event)">{{ $t('user.terms') }}</a>
                </b-form-checkbox>
              </div>
              <div class="align-self-center">
                <processing-button
                  variant="primary"
                  size="md"
                  :label="$t('user.login-button')"
                  class="ml-2 btn-shadow"
                  :processing="processing"
                ></processing-button>
              </div>
            </div>
          </b-form>
        </div>
      </b-card>
    </b-colxx>

    <b-modal
      v-model="showModal"
      id="modalVmb"
      ref="modalVmb"
      modal-class="modalVmb"
      size="lg"
      hide-header
      hide-footer
    >
      <vue-perfect-scrollbar
        class="scroll terms"
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
      >
        <h1>Terms of Use</h1>
        <ol v-if="terms.type === 1" class="all">
          <li v-for="(lev1, lev1Index) in terms.data" :key="lev1Index">
            <b>{{ lev1.title }}</b>
            <ol class="number mt-2 mb-2">
              <li v-for="(lev2, lev2Index) in lev1.section" :key="lev2Index" class="mt-2">
                <span v-html="lev2.section"></span>
                <ol class="alpha mt-2" v-if="lev2.subSection">
                  <li v-for="(lev3, lev3Index) in lev2.subSection" :key="lev3Index">
                    <span v-html="lev3.section"></span>
                    <ol class="roman" v-if="lev3.subSection">
                      <li v-for="(lev4, lev4Index) in lev3.subSection" :key="lev4Index">
                        <span v-html="lev4.section"></span>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <div v-else-if="terms.type === 2">
          <p v-for="(item, index) in terms.data" :key="index" class="terms">
            {{ item.section }}
          </p>
        </div>
        <div v-else></div>
        <div class="d-flex float-right mt-3">
          <b-button variant="light" size="sm" @click="hideModal">CANCEL</b-button>
          <b-button v-if="!termsAccepted" variant="primary" size="sm" class="ml-2" @click="setTerms"
            >I AGREE</b-button
          >
        </div>
      </vue-perfect-scrollbar>
    </b-modal>
  </b-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { languageMixin } from '@/mixins/languageMixin'
import { validationMixin } from 'vuelidate'
const { required, maxLength, minLength, email } = require('vuelidate/lib/validators')
import ApplicationHighlights from '@/components/Common/ApplicationHighlights.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
export default {
  components: {
    'app-highlights': ApplicationHighlights,
    ProcessingButton
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      appTitle: config.VUE_APP_TITLE || process.env.VUE_APP_TITLE,
      processing: false,
      returnUrl: '/',
      showModal: false,
      showPassword: false,
      terms: this.$t(`terms.${config.VUE_APP_LOCALE}`),
      termsAccepted: false
    }
  },
  mixins: [languageMixin, validationMixin],
  validations: {
    form: {
      password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(8),
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        }
      },
      email: {
        required,
        email,
        minLength: minLength(4)
      }
    }
  },
  computed: {
    ...mapState('notification', ['notifications']),
    ...mapGetters(['currentUser', 'authCheck', 'currentLanguage'])
  },
  methods: {
    ...mapActions(['login', 'fetchProfile', 'setLanguage']),
    ...mapActions('notification', ['resetNotifications']),
    async formSubmit() {
      let self = this
      if (!self.termsAccepted) {
        self.$notify('error', 'Please accept the Terms of Use', null, {
          duration: 3000,
          permanent: false
        })
        return
      } else {
        await self.$v.$touch()
        await self.$v.form.$touch()
        if (!self.$v.form.$anyError) {
          // clear all notifications if any
          if (self.notifications.length > 0) {
            self.resetNotifications()
          }

          self.processing = true
          let credentials = {
            email: this.form.email,
            password: this.form.password
          }
          const status = await self.login(credentials)
          if (status.status === 200) {
            await self.fetchProfile()
            await self.$router.replace(self.returnUrl)
          }

          self.processing = false

          // save terms acceptance
          localStorage.setItem('termsAccepted', this.termsAccepted)
        }
      }
    },
    openModal(event) {
      event.preventDefault()
      this.showModal = true
    },
    hideModal() {
      this.showModal = false
    },
    onChangeItem(event) {
      this.termsAccepted = event
    },
    setTerms() {
      // let self = this;
      this.termsAccepted = true
      this.hideModal()
    }
  },
  mounted() {
    let self = this
    self.setCurrentLanguage()

    if (localStorage.getItem('termsAccepted')) {
      self.termsAccepted = localStorage.getItem('termsAccepted')
    }
  },
  created() {
    let self = this
    if (self.authCheck) {
      self.$router.push({ name: 'home' })
    }
    self.returnUrl = self.$route.query.returnUrl || '/'
  }
}
</script>
