var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "carousel",
    {
      staticClass:
        "application-highlights-glide d-flex flex-row row justify-content-center",
      attrs: {
        "per-page": 1,
        "mouse-drag": true,
        autoplay: true,
        autoplayHoverPause: true,
        loop: true,
        autoplayTimeout: _vm.autoplayTimeout,
      },
    },
    _vm._l(_vm.appHighlights, function (app, index) {
      return _c("slide", { key: `app-${index}`, staticClass: "px-3" }, [
        _c(
          "div",
          { staticClass: "col-12 glide-main pt-3" },
          [
            _c("b-row", { attrs: { cols: "p-0 m-0" } }, [
              _c(
                "div",
                { staticClass: "col-12 my-4", attrs: { align: "center" } },
                [_c("img", { attrs: { src: app.img } })]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-12", attrs: { align: "center" } }, [
                _c("p", { domProps: { innerHTML: _vm._s(app.title) } }),
              ]),
            ]),
          ],
          1
        ),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }