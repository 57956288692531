var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "h-100" },
    [
      _c(
        "b-colxx",
        { staticClass: "mx-auto my-auto", attrs: { xxs: "12", md: "10" } },
        [
          _c("b-card", { staticClass: "auth-card", attrs: { "no-body": "" } }, [
            _c("div", { staticClass: "position-relative image-side" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-side" },
              [
                _c("span", { staticClass: "logo-text" }, [
                  _vm._v(_vm._s(_vm.appTitle)),
                ]),
                _vm._v(" "),
                _c("h6", { staticClass: "mb-4" }, [_vm._v("Register")]),
                _vm._v(" "),
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.formSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "mb-4" },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [
                            _c("i", {
                              staticClass: "demo-icon simple-icon-user",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state: !_vm.$v.form.email.$error,
                            placeholder: "Email Address",
                          },
                          model: {
                            value: _vm.$v.form.email.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.form.email, "$model", $$v)
                            },
                            expression: "$v.form.email.$model",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.$v.form.email.required
                          ? _c("div", { staticClass: "invalid-tooltip" }, [
                              _vm._v("Please enter your email address"),
                            ])
                          : !_vm.$v.form.email.email
                          ? _c("div", { staticClass: "invalid-tooltip" }, [
                              _vm._v("Please enter a valid email address"),
                            ])
                          : !_vm.$v.form.email.minLength
                          ? _c("div", { staticClass: "invalid-tooltip" }, [
                              _vm._v("Your email must be minimum 4 characters"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-5" },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [
                            _c("i", {
                              staticClass: "demo-icon simple-icon-lock",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            type: "password",
                            state: !_vm.$v.form.password.$error,
                            placeholder: "Password",
                          },
                          model: {
                            value: _vm.$v.form.password.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.form.password, "$model", $$v)
                            },
                            expression: "$v.form.password.$model",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.$v.form.password.required
                          ? _c("div", { staticClass: "invalid-tooltip" }, [
                              _vm._v("Please enter your password"),
                            ])
                          : !_vm.$v.form.password.minLength ||
                            !_vm.$v.form.password.maxLength
                          ? _c("div", { staticClass: "invalid-tooltip" }, [
                              _vm._v(
                                "Your password must be between 4 and 16 characters"
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-input-group",
                      { staticClass: "mb-5" },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [
                            _c("i", {
                              staticClass: "demo-icon simple-icon-lock",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            type: "password",
                            state: !_vm.$v.form.confirmPassword.$error,
                            placeholder: "Confirm Password",
                          },
                          model: {
                            value: _vm.$v.form.confirmPassword.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.form.confirmPassword,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.form.confirmPassword.$model",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.$v.form.confirmPassword.required
                          ? _c("div", { staticClass: "invalid-tooltip" }, [
                              _vm._v("Please enter your password"),
                            ])
                          : !_vm.$v.form.confirmPassword.minLength ||
                            !_vm.$v.form.confirmPassword.maxLength
                          ? _c("div", { staticClass: "invalid-tooltip" }, [
                              _vm._v(
                                "Your password must be between 4 and 16 characters"
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center",
                      },
                      [
                        _c("router-link", { attrs: { tag: "a", to: "/" } }, [
                          _vm._v("Cancel"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "btn-shadow",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              size: "lg",
                            },
                          },
                          [_vm._v("REGISTER")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }