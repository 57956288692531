var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-toast",
    {
      staticClass: "notification-toast",
      attrs: {
        id: _vm.notification.id,
        title: _vm.notification.title,
        message: _vm.notification.message,
        variant: _vm.setVariant(_vm.notification.type),
        solid: _vm.solid,
        toaster: _vm.toaster,
        append: _vm.append,
        "no-close-button": "",
        "no-auto-hide": "",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.notification.message) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }